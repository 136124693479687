import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/SEO";
import {
  PageSection,
  PageSectionArticle,
  PageSectionWrapper,
  TextEditor,
} from "../components/common";
import { toHTML } from "../utils/helpers";

const TermsPageTemplate = ({ title, termsData }) => {
  const { heading, body } = termsData;
  return (
    <>
      <SEO title={title} />
      <PageSectionWrapper bgColor="var(--creamWhite)">
        <PageSection bgColor="var(--creamWhite)">
          <PageSectionArticle>
            <h1>{heading}</h1>
            <TextEditor
              style={{ maxWidth: "900px" }}
              dangerouslySetInnerHTML={{ __html: toHTML(body) }}
            />
          </PageSectionArticle>
        </PageSection>
      </PageSectionWrapper>
    </>
  );
};

const TermsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <TermsPageTemplate
      title={frontmatter.title}
      termsData={frontmatter.termsData}
    />
  );
};

export default TermsPage;

export const pageQuery = graphql`
  query TermsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        termsData {
          heading
          body
        }
      }
    }
  }
`;
